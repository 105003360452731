import React, { useContext, useState } from "react"
import { Button, TextField } from "@material-ui/core"
import FirebaseContext from "../../components/firebase/context"
import Content from "../../components/content"
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function Songwish() {

  const [formValues, setFormValues] = useState({user: '', song: '', artist: ''});
  const { firebase, user  } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = React.useState(false);

  function SubmitButton(){
    if (formValues.artist && formValues.song && formValues.user){
      return <Button style={{'marginTop':'1em', 'whiteSpace': 'nowrap', 'backgroundColor': '#FAD5AB'}} variant="outlined" type="submit" >Lähetä</Button>
    } else {
      return <Button style={{'marginTop':'1em', 'whiteSpace': 'nowrap', 'backgroundColor': '#FAD5AB'}} variant="outlined" type="submit"  disabled>Lähetä</Button>
    };
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  function handleSubmit(e) {
      e.preventDefault();
      handleClick();
      firebase.addSong({kappale: formValues.song, soittaja: formValues.artist, toivoja: formValues.user, loggedIn : user })
      .then(() => {
        setFormValues({user: '', song: '', artist: ''})
        handleClick();
      })
      .catch(error => {
        setErrorMessage('Kirjaudu sisään laulutoivetta varten');
      });
    }
  
  function handleInputState(e) {
      e.persist();
      setFormValues(currentValues => ({
          ...currentValues,
          [e.target.name]: e.target.value
      }));
  }
  return (
    <section>
      <Content>
      <h1>Laulutoive</h1>
      <p style={{'marginBottom': '2em'}}>Mitä ovat juhlat ilman musiikkia? No pelkkää pönötystä! Näissä juhlissa musiikkia tarjoillaan sekä livenä että nauhurilta. Pyydämmekin nyt vinkkaamaan meille, mikä saisi juuri sinut tanssilattialle. Laita alla olevan kentän kautta toiveesi - tai vaikka useampi!</p>
      <form onSubmit={handleSubmit}>
        <TextField required style={{'marginBottom':'1em'}} required value={formValues.user} name="user" onChange={handleInputState} placeholder="Toivoja" />
        <TextField required style={{'marginBottom':'1em'}} required value={formValues.song} name="song" onChange={handleInputState} placeholder="Kappale" />
        <TextField required style={{'marginBottom':'1em'}} required value={formValues.artist} name="artist" onChange={handleInputState} placeholder="Artisti"/>
        {!!errorMessage && 
          <span style={{'color':'red'}}>{errorMessage}</span>
        }
        <SubmitButton>Lähetä</SubmitButton>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Rock! Toiveesi on kuultu"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </form>
    </Content>
    </section>
  )
}